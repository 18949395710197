import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// Import images
import Image1 from './hubs-logo.svg'; // Update this path
import Image2 from './nexus-logo2.svg'; // Update this path
import citiwaveLogo from './logo.svg'; // Import the logo image
import arrow from './arrow-icon.png'; // Import the logo image


function TilesPage() {
  return (
      <div className="App">
          <img src={citiwaveLogo} alt="Citiwave Logo" className="logo" /> {/* Add the logo here */}
      <h2>Applications</h2>
      <div className="tile-container">
              <a href="https://hubs.citiwave.io" className="tile" target="_blank" rel="noopener noreferrer">
          <img src={Image1} alt="Portal" className="tile-icon" />
          <div className="text-container">
            <h3>Portal</h3>
            <p>Manage your organizations and workspaces. </p>
          </div>
          {/* Replace 'ArrowIconPath' with the actual path to your arrow icon */}
                  <img src={arrow} alt="Arrow" className="arrow-icon" />
          </a>
        

        <a href="#" className="tile" target="_blank" rel="noopener noreferrer">
          <img src={Image2} alt="API" className="tile-icon" />
              <div className="text-container">
                <h3>API</h3>
                <p><b>Currently in private beta</b>. Embed environmental and social verified impact awareness into AI and decision making workflows for any application.</p>
              </div>
          {/* Replace 'ArrowIconPath' with the actual path to your arrow icon */}
          <img src={arrow} alt="Arrow" className="arrow-icon" />
        </a>

      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App grid-background">
       
          <Routes>
            <Route path="/apps" element={<TilesPage />} />
            <Route path="/" element={<Navigate replace to="/apps" />} />
          </Routes>
        </div>
    </Router>
  );
}

export default App;
